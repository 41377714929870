import React from "react"
import { getCourseNavigation } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import AudioPlayer from "../../../../components/audio-player"
import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import Heading from "../../../../components/heading"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/lesen-und-einpraegen" />
      }
      navigation={navigation}
    >
      <Seo title="Zwei Hälften des Lebens" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 1
            </Heading>
            <Heading as="h2" level={2}>
              Zwei Hälften des Lebens
            </Heading>
          </Stack>
          <AudioPlayer src={`kurse/haelfte-des-lebens/einleitung`} />
          <Paragraph dropcap={true}>
            Es scheint ein merkwürdiger Zufall, dass Hölderlins Leben passend
            zum Titel seines heute bekanntesten Gedichts in zwei Hälften
            zerfiel: In den ersten, von Auf- und Abbrüchen gezeichneten 36
            Jahren seines Lebens hat er ganze 12 Mal die Wohnorte gewechselt.
            Seine letzten 36 Lebensjahre verbrachte er in einem Tübinger Turm am
            Neckar, von dem er sich kaum mehr wegbewegte.
          </Paragraph>
          <Paragraph>
            Zwei früh verstorbene Väter, zehn streng regulierte und
            disziplinierte Schul- und Studienjahre an den evangelischen
            Bildungsanstalten Württembergs und häufig wechselnde
            Hauslehrerstellen, die ihm den einzigen Ausweg aus der für ihn
            vorgesehenen Theologen-Laufbahn boten, prägten die erste Hälfte
            seines Lebens. Erste literarische Erfolge, Kontakte zu Schiller und
            Goethe, aber auch herbe Enttäuschungen und Misserfolge widerfuhren
            ihm bei seinem Versuch, als Schriftsteller Fuß zu fassen. Vom
            Studium am Tübinger Stift führte der Weg nach Waltershausen an der
            Saale und Frankfurt am Main, nach Bad Homburg und Stuttgart, ins
            schweizerische Hauptwil und schließlich bis nach Bordeaux. Nirgends
            hielt es ihn länger als 2-3 Jahre, manchmal sogar nur wenige Monate.
            Dazwischen verliert er die Liebe seines Lebens, Susette Gontard, die
            Ehefrau seines Frankfurter Arbeitgebers, und muss deren frühen Tod
            verkraften. In den Jahren zwischen 1802 und 1806 verschlechtert sich
            sein psychischer Zustand dabei mehr und mehr.
          </Paragraph>
          <Paragraph>
            Durch die Mutter veranlasst, wird Hölderlin Mitte September 1806 ins
            Tübinger Klinikum eingeliefert, wo er 231 Tage lang psychiatrisch
            behandelt wird. Nach seiner Entlassung im Mai 1807 nimmt ihn die
            Familie des Schreinermeisters Ernst Zimmer in ihrem Haus am Tübinger
            Neckar auf. Bis zu seinem Tod im Jahr 1843 wird Hölderlin dort
            bleiben. Der Unstetigkeit, von der die erste Hälfte seines Lebens
            geprägt war, steht die geradezu monotone Regelmäßigkeit jener
            zweiten Lebenshälfte gegenüber. Schweiften seine früheren Gedichte
            von der vertrauten Heimat, dem Neckartal und den dortigen
            Weinbergen, weit aus bis nach Griechenland, so beschränkten sich die
            sogenannten Turmgedichte auf das, was ihm nun unmittelbar vor Augen
            stand: Die Aussicht aus seinem Turmzimmer und der sich davor
            abzeichnende Wechsel der Jahreszeiten.
          </Paragraph>
          <Paragraph>
            Das Gedicht ›Hälfte des Lebens‹ entstand jedoch bereits im Jahr
            1803, als einiges im Wanken war, aber noch nicht alles verloren
            schien. Hölderlin war 33 Jahre alt und noch nicht ganz in der Hälfte
            seines Lebens. Christoph Theodor Schwab, der Herausgeber seiner
            ›Gesammelten Werke‹ rechnete ›Hälfte des Lebens‹ unter die Zeugnisse
            »aus der Zeit des Irrsinns«.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
